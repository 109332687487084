import { useEffect, useState } from 'react';

const useTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialsLoading, setLoading] = useState(true);
  useEffect(() => {
    fetch('testimonials.json')
      .then((res) => res.json())
      .then((data) => {
        setTestimonials(data);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      
  }, []);
  return [testimonials, testimonialsLoading];
};

export default useTestimonials;
