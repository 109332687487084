import React from "react";

const TestimonialsModal = ({ id, testimonial }) => {
  const starCount = [];

  for (let i = 0; i < parseInt(testimonial.rating); i++) {
    starCount.push("fa-solid fa-star");
  }
  if (parseInt(testimonial.rating) !== testimonial.rating) {
    starCount.push("fa-solid fa-star-half");
  }

  return (
    <div>
      <input type="checkbox" id={id} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor={id}
            className="btn btn-sm  btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <div className=" md:mr-5  p-4 ">
            <div className="pb-4">
              <img
                src={testimonial.image}
                alt=""
                className="rounded-full  h-[150px] "
              />
            </div>

            <div className="flex justify-between pb-2">
              <div className="flex flex-col">
                <h1 className="text-xl font-bold">{testimonial.name}</h1>
                <h1 className="text-lg font-semibold">{testimonial.company}</h1>
              </div>

              <div className="text-[#FFD700] w-[160px]">
                {starCount.map((item, index) => (
                  <i key={index} className={item}></i>
                ))}
              </div>
            </div>

            <div>
              <p className="text-lg text-justify">{testimonial.review}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsModal;
