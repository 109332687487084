import React, { useState } from "react";
import "./FAQ.css";
import faqBanner from "../../resource/FAQs.gif";
import FAQItems from "./FAQItems";


const FAQ = ({faq}) => {

  const [expandManagement,setExpandManagement] =useState([]);

  faq.map((item,index)=>expandManagement.push(index?false:true));

  const manageExpand=(index)=>{
    let reset = [];
    faq.map(item=>reset.push(false));
    reset[index]=true;
    setExpandManagement(reset);
  }

  return (
    <div id="faq" className="bg-gray-100 pt-20 py-16">
      <div className="container  mx-auto  px-2 lg:px-10 ">
        <h1 className="text-4xl lg:text-5xl font-bold pb-5 ">
          Frequently Asked <br /> Questions
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2  items-center">
          <div>
            {faq.map((item, index) => (
              <FAQItems key={index} index={index} questionAnswer={item} expandManagement={expandManagement}  manageExpand={manageExpand}/>
            ))}
          </div>

          <div className="mx-auto">
            <img src={faqBanner} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
