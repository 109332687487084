import React, { useRef, useState } from "react";
import ReactGA from 'react-ga';
import { toast } from "react-toastify";
import { handelError, saveToSpreadSheet, sendEmail } from "../../utility/Utility";

const JoinModal = () => {
  const form = useRef();

  
  const [pumpNameError, setPumpNameError] = useState();
  const [modalEmailError, setModalEmailError] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState();
  const [locationError, setLocationError] = useState();
  const [establishmentError, setEstablishmentError] = useState();
 


  const handelSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Submit',
      action: 'Join Waitlist'
    });
    const data = {
      Email_ID: event.target.emailId.value,
      Location: event.target.location.value,
      Phone_Number: event.target.phoneNumber.value,
      Petrol_Pump_Name: event.target.petrolPumpName.value,
      Establishment_Year: event.target.establishment_year.value,
    };

    const modalError = pumpNameError || modalEmailError || phoneNumberError || locationError || establishmentError;
    if(modalError){
      toast.error(modalError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }else{
      saveToSpreadSheet(
        "https://api.apispreadsheets.com/data/cpIAbfjLhepLMJBT/",
        data,true
      );
  
      sendEmail(
        event,
        form,
        "service_mfk3jqe",
        "template_vfojryt",
        "JnYS958Pmm11LFY7I",
        false
      );     
    }
   
  };
  return (
    <div>
      <input type="checkbox" id="my-modal-3" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm  btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <form
            ref={form}
            className="flex flex-col items-center gap-5 pt-10"
            onSubmit={handelSubmit}
          >
            <input
             onChange={(event) => handelError(event,"Pump Name", setPumpNameError, 100)}
              type="text"
              name="petrolPumpName"
              placeholder="Name Of Petrol Pump"
              className={pumpNameError?"input input-bordered  border-[red] w-full":"input input-bordered w-full"}
              required
            />
            <input
            onChange={(event) => handelError(event,"Email", setModalEmailError, 100)}
              type="email"
              name="emailId"
              placeholder="Email"
              className={modalEmailError?"input input-bordered  border-[red] w-full":"input input-bordered w-full"}
              required
            />
            <input
            onChange={(event) => handelError(event,"Phone Number", setPhoneNumberError, 15)}
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              className={phoneNumberError?"input input-bordered  border-[red] w-full":"input input-bordered w-full"}
              required
            />
            <input
            onChange={(event) => handelError(event,"Location", setLocationError, 200)}
              type="text"
              name="location"
              placeholder="Location"
              className={locationError?"input input-bordered  border-[red] w-full":"input input-bordered w-full"}
              required
            />
            <input
            onChange={(event) => handelError(event,"Establishment", setEstablishmentError,4)}
              type="number"
              name="establishment_year"
              placeholder="Year of Establishment"
              className={establishmentError?"input input-bordered  border-[red] w-full":"input input-bordered w-full"}
              required
            />
            <button type="submit" className="joinBtn text-lg btn-wide">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinModal;
