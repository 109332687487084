import { useEffect, useState } from 'react';

const useFAQ = () => {
  const [faq, setFaq] = useState([]);
  const [faqLoading, setLoading] = useState(true);
  useEffect(() => {
    fetch('faq.json')
      .then((res) => res.json())
      .then((data) => {
        setFaq(data);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      
  }, []);
  return [faq, faqLoading];
};

export default useFAQ;
