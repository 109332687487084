import React, { useState } from "react";
import logo from "../../../resource/logo.png"
import { NavLink } from "react-router-dom";
import "./NavBar.css"
import SideNavBar from "./SideNavBar";

const NavBar = () => {
  
  const [navOn,setNavOn] =useState(false)
  const [navBar, setNavBar] = useState(false);

  const navigation = (
    <>
    <li >
        <a href="#faq" className="notActive  text-2xl">
          FAQ
        </a>
      </li>
      <li>
        <a href="#contact" className="notActive  text-2xl mr-5">
          Contact
        </a>
      </li>
      <label htmlFor="my-modal-3" className="flex justify-center items-center navJoin text-2xl ">
        Join The Waitlist
      </label>

      
    </>
  );
 
 
  const switchSideNav=()=>{
    setNavOn(!navOn);
   }

   const changeBgScroll = () => {
    if (window.scrollY >= 100) {
        setNavBar(true)
    } else {
        setNavBar(false);
    }
}
window.addEventListener('scroll', changeBgScroll);
const navBarStyleBase ="py-3 sticky top-0 z-50";
const navBarStyleWithColor = "py-3 sticky top-0 z-50 bg-gray-200";

const appLogo = (
  <a href="#home" className="navLogo hover:lg:scale-[1.03] text-black normal-case text-3xl lg:scale-110">
    <img src={logo} className="h-10 pr-2 " alt="" />
    FuelWorks
  </a>
);


  return (
    <div  className={navBar?navBarStyleWithColor:navBarStyleBase}>       
      <div className="xl:container mx-auto  lg:px-4">
        <div className="flex justify-between items-center ">

          {/* mobile viwe navbar */}
          
          <div className="dropdown navbar-end lg:hidden">
          <SideNavBar navOn={navOn} switchSideNav={switchSideNav} />          
            <button className="btn btn-ghost lg:hidden" onClick={switchSideNav}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </button>           
          </div> 
          

          {/* logo link for mobile view */}
          <div className="flex justify-end items-end pr-3 lg:hidden">{appLogo}</div>

          
        </div>

        <div className="hidden flex flex-row  items-center lg:flex justify-between text-white text-lg font-bold">

          {/* Logo link for desktop view           */}
          <span className="hidden lg:block"> {appLogo}</span>
          
          {/* desktop view navbar */}
          <div className="menu menu-horizontal justify-items-center ">
            {navigation}
          </div>
          
        </div>

        
      </div>
    </div>
  );
};

export default NavBar;