import { Route, Routes } from "react-router-dom";
import HomePage from "./page/HomePage";
import ReactGA from 'react-ga';


function App() {
  const TRACKING_ID = "UA-253072871-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
