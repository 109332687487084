import React from "react";
import "./NavBar.css";

const SideNavBar = ({ navOn, switchSideNav }) => {

  const navigationPhone = (
    <>
      <button onClick={switchSideNav}>
        <a href="#faq" className="notActive  text-2xl">
          FAQ
        </a>
      </button>
      <button onClick={switchSideNav}>
        <a href="#contact" className="notActive  text-2xl mr-5">
          Contact
        </a>
      </button>
      <label htmlFor="my-modal-3" className="flex justify-center items-center navJoin text-2xl ">
        Join The Waitlist
      </label> 
    </>
  );

  return (
    <div className={navOn ? "sideNavBarOn ml-0 w-[100vw] h-[100vh]" : "sideNavBarOf"}>
      {navOn && (
        <div>
          <div className="flex justify-end p-2">
            <button className=" text-2xl  bgClose" onClick={switchSideNav}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="sideNavRoute text-xl">
            {navigationPhone}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNavBar;
