import React from "react";
import "./KeyFeature.css";
import KeyFeatureCard from "./KeyFeatureCard";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const KeyFeature = ({keyFeature}) => {

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
       
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1       
            }
          }
      ]
  };
  return (
    <div className="container mx-auto mb-12 pt-12 lg:pt-0">
        <Slider {...settings}>
          {keyFeature.map((item, index) => (
            <KeyFeatureCard key={index} feature={item} />
          ))}
        </Slider>
    </div>
  );
};

export default KeyFeature;
