import React from "react";
import ReactGA from 'react-ga';
import "./HomeBanner.css";
import bannerImage from "../../resource/banner.gif";
import JoinModal from "./JoinModal";
import { ToastContainer } from "react-toastify";

const HomeBanner = () => {
  return (
    <div id="home" className="container mx-auto lg:pb-24 pb-10">
      <div className="hero px-2 lg:px-0">
        <div className="grid grid-cols-1  lg:grid-cols-2 items-center justify-items-center">
          <div className="order-2 lg:order-1">
            <h1 className="text-5xl lg:text-8xl lg:text-start font-bold text-center ">
              Fuel your Business with <span className="text-[#90CAF9]">Fuel Works</span>.
            </h1>
            <p className="py-6 text-2xl text-center lg:text-start">
              Cloud-based Software to easily manage and run your Petrol Pump
            </p>
            <div className="flex justify-center lg:justify-start">
              <label onClick={() => ReactGA.event({
                category: 'Open',
                action: 'Join Waitlist Modal'
              })} htmlFor="my-modal-3" className="joinBtn text-xl">Join The Waitlist</label>
            </div>

          </div>
          <div className="order-1 lg:order-2">
            <img
              src={bannerImage}
              className="h-[300px] lg:h-auto"
              alt="asdf" />
          </div>
        </div>
      </div>
      <JoinModal />
      <ToastContainer />

    </div>

  );
};

export default HomeBanner;
