import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
  

  <div class="load">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
  </div>

  
  );
};

export default Loading;
