import React from "react";
import "./KeyFeature.css";

const KeyFeatureCard = ({feature}) => {

  return (
    <div className="featureCard  w-full p-3 " >  
      <div className="bgOverlay ">        
        <div className="flex justify-center ">
          <img src={feature.image} alt="" className=" h-[100px] icon"/>
          
        </div>
        <h2 className="text-md md:text-md  lg:text-lg xl:text-xl 2xl:text-2xl font-bold pt-4 h-[65px]">
          {feature.heading}
        </h2>
      </div>
    </div>
  );
};

export default KeyFeatureCard;
