import { useEffect, useState } from 'react';

const useKeyFeature = () => {
  const [keyFeature, setKeyFeature] = useState([]);
  const [featureLoading, setLoading] = useState(true);
  useEffect(() => {
    fetch('keyFeature.json')
      .then((res) => res.json())
      .then((data) => {
        setKeyFeature(data);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
    
  }, []);
  return [keyFeature, featureLoading];
};

export default useKeyFeature;
