import React from "react";
import "./Footer.css";
import logo from "../../../resource/logo.png";
import { Link, Navigate } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footerSection bg-gray-100">
      <div>
        <div className="grid grid-cols-2 md:grid-cols-6 item-center justify-center container mx-auto pb-12 lg:py-12">
          <div className="mt-10 text-center md:text-left col-span-2 md:grid-cols-1  px-5 md:pl-8 lg:pl-16  xl:pl-28 2xl:pl-40">
            <a
              href=""
              className="flex justify-center md:justify-start items-center text-3xl lg:text-2xl pb-5 lg:pb-0 text-black font-semibold"
            >
              <img className="w-[15%] lg:w-10 pr-2" src={logo} alt="" />
              FuelWorks
            </a>

           

            <a   href="tel:+91 9664761176" className="text-2xl text-sky-500 font-medium">+91 9664761176</a>
          </div>

          <div className="w-28 mt-10 md:pl-12  xl:pl-10 text-black item-center font-base justify-center container mx-auto">
            <h1 className="font-medium text-xl">ABOUT</h1>
            <p className="py-1 pt-7">
              <a href="#home" className="hover:font-bold">
                Home
              </a>
            </p>
            <p className="py-2">
              <a href="#faq" className="hover:font-bold">
                FAQ
              </a>
            </p>

            <p className="py-1">
              <a href="#contact" className="hover:font-bold">
                Contact Us
              </a>
            </p>
          </div>

          <div className="w-28 mt-10 xl:pl-16 md:pl-16 text-black item-center font-base justify-center container mx-auto">
            <h1 className="font-medium text-xl ">CONTACT</h1>
            <p className="py-1 pt-7 font-medium text-sky-500">
              <p>ADDRESS</p>
            </p>
            <p className="py-1 pr-3 md:pr-3 md:w-36 lg:w-60">
              <p>South Bopal, Ahmedabad, Gujarat</p>
            </p>

            <p className="py-1 pt-2 lg:pt-7 font-medium text-sky-500">
              <p>EMAIL</p>
            </p>
            <p className="py-1">
              <a  href="mailto:hello@fuelworks.in">hello@fuelworks.in</a>
            </p>
          </div>

          <div className="w-30 text-black col-span-2 md:grid-cols-1 text-center">
            <h1 className="font-medium mt-10 text-xl md:pl-6">FOLLOW</h1>
            <div className="mt-7 md:text-right md:pr-3 lg:text-center lg:pl-20 ">
              <a target="_blank" href="https://www.facebook.com/">
                <i className="icons text-2xl p-2 border-4 hover:text-blue-500 hover:border-blue-500 fa-brands fa-square-facebook" />
              </a>

              <a className="mx-3" target="_blank" href="https://www.linkedin.com/company/fuelworks22/">
                <i className="icons text-2xl p-2 border-4 hover:text-blue-800 hover:border-blue-800 fa-brands fa-linkedin" />
              </a>
              <a target="_blank" href="https://twitter.com/Fuelworks21">
                <i className="icons text-2xl p-2 border-4 hover:text-blue-300 hover:border-blue-300 fa-brands fa-square-twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-200">
        <div className="py-7">
          <p className="text-black text-center text-lg hidden lg:block">
            &#169;2022 Copyrights are reserved by Stitchwork Software Pvt Ltd 
          </p>
          <p className="text-black text-center text-lg lg:hidden ">
            &#169;2022 Copyrights are reserved by Stitchwork Software Pvt Ltd
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
