import { useEffect, useState } from 'react';

const useTeams = () => {
  const [teams, setTeams] = useState([]);
  const [teamsLoading, setLoading] = useState(true);
  useEffect(() => {
    fetch('teamInfo.json')
      .then((res) => res.json())
      .then((data) => {
        setTeams(data);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      
  }, []);
  return [teams, teamsLoading];
};

export default useTeams;
