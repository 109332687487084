import React from "react";
import ContactForm from "./ContactForm";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div id="contact" className="pt-24 pb-10">
      <div className="container mx-auto">
        <h2 className="text-4xl lg:text-5xl text-start font-[700] px-5 headline">
          Get In <span className="underline">Touch</span>
        </h2>

        <div className="grid grid-cols-2 mt-10 px-5 lg:px-10">
          <div className="col-span-12 lg:col-span-1 order-1 lg:order-1">
            <ContactForm />
          </div>

          <div className=" flex flex-col items-center  gap-y-16 mt-10 lg:mt-0 col-span-12 lg:col-span-1 order-2 lg:order-2 ">
            <div className="flex flex-col justify-between h-[60vh] lg:h-5/6 lg:w-4/4 contactBox p-4 lg:p-8">
              <div>
                <h1 className="text-3xl font-semibold pb-3">
                  Contact Information
                </h1>
                <h2 className="text-xl font-normal">
                  Fill up the form and our team will get back to you withing 24
                  hours.
                </h2>
              </div>

              <div>
                <a
                  href="mailto:hello@fuelworks.in"
                  className="flex flex-row items-center gap-2 text-2xl pb-5 mail"
                >
                  <i className="fa-solid fa-envelope"></i>
                  <p className="break-words">hello@fuelworks.in</p>
                </a>
                <a
                  href="tel:+91 9664761176"
                  className="flex flex-row items-center  gap-2 text-2xl phone"
                >
                  <i className="fa-solid fa-phone "></i>
                  <p>+91 9664761176</p>
                </a>
              </div>

              <div className=" flex gap-5">
                <a target="_blank" href="https://www.facebook.com/">
                  <i className=" text-4xl fa-brands fa-square-facebook hover:text-blue-500"/>
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/fuelworks22/">
                  <i className=" text-4xl fa-brands fa-linkedin hover:text-blue-800"/>
                </a>
                <a target="_blank" href="https://twitter.com/Fuelworks21">
                  <i className=" text-4xl fa-brands fa-square-twitter hover:text-blue-300"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
