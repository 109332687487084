import React from "react";
import ContactUs from "../component/contactUs/ContactUs";
import FAQ from "../component/faq/FAQ";
import HomeBanner from "../component/homeBanner/HomeBanner";
import KeyFeature from "../component/keyFeature/KeyFeature";
import Footer from "../component/shared/footer/Footer";
import Loading from "../component/shared/Loading/Loading";
import NavBar from "../component/shared/navBar/NavBar";
import TeamList from "../component/teams/TeamList";
import Testimonials from "../component/testimonials/Testimonials";
import WhyUs from "../component/whyUs/WhyUs";
import useFAQ from "../hooks/useFAQ";
import useKeyFeature from "../hooks/useKeyFeature";
import useTeams from "../hooks/useTeams";
import useTestimonials from "../hooks/useTestimonials";

const HomePage = () => {
  const [keyFeature,featureLoading] = useKeyFeature();
  const [testimonials, testimonialsLoading] = useTestimonials();
  const [teams, teamsLoading] = useTeams();
  const [faq, faqLoading] = useFAQ();

  if(featureLoading || testimonialsLoading || teamsLoading || faqLoading){
    return <Loading/>
  }
  return (
    <div>
      <NavBar />
      <HomeBanner />
      <WhyUs  keyFeature={keyFeature}/>
      <Testimonials testimonials={testimonials}/>
      {/* <TeamList teams={teams}/> */}
      <FAQ faq={faq}/>
      <ContactUs/>
      <Footer />
    </div>
  );
};

export default HomePage;
