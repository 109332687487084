import React from 'react';
import "./FAQ.css";


const FAQItems = ({index,questionAnswer,expandManagement, manageExpand}) => {

   
    const handleExpand=()=>{
      manageExpand(index);       
    }
    const inactive = "font-semibold p-5 border-2 border-black flex justify-between w-full";
    const active = "bg-black text-white font-semibold p-5 border-2 border-black flex justify-between w-full cursor-default"
    return (
        <div className='w-auto'>
          <button type='button'  onClick={handleExpand} className={expandManagement[index]?active:inactive}>
            <div>
              <h1 className='text-xl'>{questionAnswer.question}</h1>
            </div>            
            <i className={expandManagement[index] ?"fa-solid fa-angle-up ":"fa-solid fa-angle-down "}></i>            
          </button>

          <div className={expandManagement[index]? "answerRow":"answerRowHidden"}>
            <p className=" text-justify">{questionAnswer.answer}</p>
          </div>

        </div>
    );
};

export default FAQItems;