import React from "react";


const TestimonialsCard = ({id, testimonial }) => {
  const starCount = [];

  for (let i = 0; i < parseInt(testimonial.rating); i++) {
    starCount.push("fa-solid fa-star");
  }
  if (parseInt(testimonial.rating) !== testimonial.rating) {
    starCount.push("fa-solid fa-star-half");
  }

  return (
    <div className="shadow md:mr-5  p-4 ">
      <div className="pb-4">
        <img src={testimonial.image} alt="" className="rounded-full  h-[150px] " />
      </div>

      <div className="flex justify-between pb-2">
        <div className="flex flex-col">
        <h1 className="text-xl font-bold">{testimonial.name}</h1>
        <h1 className="text-lg font-semibold">{testimonial.company}</h1>
        </div>

        <div className="text-[#FFD700] w-[120px]">
          {starCount.map((item, index) => (
            <i key={index} className={item}></i>
          ))}
        </div>
      </div>

      <div>
        {
          testimonial.review.length>250?
          <p className="text-lg text-justify">{testimonial.review.slice(0,250)} <label htmlFor={id}  className="font-semibold cursor-pointer">...see more</label> </p>
          :
          <p className="text-lg text-justify">{testimonial.review}</p>
        }
        
      </div>
    </div>
  );
};

export default TestimonialsCard;
