import React from "react";
import "./Testimonials.css";
import TestimonialsCard from "./TestimonialsCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialsModal from "./TestimonialsModal";

const Testimonials = ({testimonials}) => {
  
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
       
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1       
            }
          }
      ]
  };
  return (
    <div className="container px-4 lg:px-10 mx-auto py-10">
      <div>
        <p className="text-start text-4xl lg:text-5xl font-bold">
          Client Testim<span className="underline">onials</span>
        </p>
      </div>
      <div className="pt-10">
        <Slider {...settings}>
          {testimonials.map((item, index) => (
            <TestimonialsCard key={index} id={index}  testimonial={item} />
          ))}
        </Slider>
      </div>
      {testimonials.map((item, index) => (
            <TestimonialsModal key={index} id={index} testimonial={item} />
        ))}
      
    </div>
  );
};

export default Testimonials;
