import React from "react";
import "./WhyUs.css";
import banner from "../../resource/whyUs.gif";
import KeyFeature from "../keyFeature/KeyFeature";

const WhyUs = ({keyFeature}) => {
  return (
    <div id="about" className="bg-gray-100 py-16 ">
      <div className="container  mx-auto  px-2 lg:px-10 ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 items-center px-4">
          <div className="flex flex-col gap-y-10">
          <h1 className="text-4xl lg:text-5xl font-bold pb-5 ">
          Why FuelWorks?
        </h1>
            <div className="flex items-center gap-4">
              <i className="fa-solid fa-circle-right text-5xl text-sky-500"></i>
              <p className="text-2xl lg:text-3xl font-semibold">
                Designed by Petrol Pump Owner for Petrol pump owners
              </p>
            </div>
            <div className="flex items-center gap-4">
            <i className="fa-solid fa-circle-right text-5xl text-sky-500"></i>
            <p className="text-2xl lg:text-3xl font-semibold">Uses modern technology</p>
            </div>

            <div className="flex items-center gap-4">
            <i className="fa-solid fa-circle-right text-5xl text-sky-500"></i>
            <p className="text-2xl lg:text-3xl font-semibold">
                Trusted by major companies like HPCL, IOC
              </p>
            </div>

            <div className="flex items-center gap-4">
            <i className="fa-solid fa-circle-right text-5xl text-sky-500"></i>
            <p className="text-2xl lg:text-3xl font-semibold">Built by IITians</p>
            </div>
          </div>

          <div className="mx-auto">
            <img src={banner} alt="" />
          </div>
        </div>
        <KeyFeature keyFeature={keyFeature}/>
      </div>
    </div>
  );
};

export default WhyUs;
