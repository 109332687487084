import React from 'react';
import emailjs from "@emailjs/browser";
import { toast } from 'react-toastify';
const success = <i className="fa-regular fa-circle-check"></i>;

export const sendEmail = (e,form,service,template,key,tost) => {
    
    emailjs
      .sendForm(
        service,
        template,
        form.current,
        key
      )
      .then(
        (result) => {
          e.target.reset();
          tost && toast.info("We Received Your Email !", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            icon: success
          });
        },
        (error) => {
           toast.error("Message didn't send!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

   export const saveToSpreadSheet=(api,data,tost)=>{
    fetch(api, {
      method: "POST",
      body: JSON.stringify({data }),
    }).then((res) => {
      if (res.status === 201) {
        tost && toast.info("We Received Your Information!", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            icon: success
          });
      }else{
      }
    });
  }

 export const handelError = (event,variable, setError, limit) => {
    const len = event.target.value.length;
    if (len > limit) {
      setError(`${variable} Character Limit Exceeded!`);
    } else {
      setError("");
    }
  };


