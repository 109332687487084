import React, { useRef } from "react";
import ReactGA from 'react-ga';
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handelError, saveToSpreadSheet, sendEmail } from "../../utility/Utility";

const ContactForm = () => {
  const form = useRef();


  const [nameError, setNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [subjectError, setSubjectError] = useState();
  const [messageError, setMessageError] = useState();

 

  const handelEmailSend = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'Submit',
      action: 'Contact Form'
    });
    const data = {
      Email: e.target.email.value,
      Message: e.target.message.value,
      Subject: e.target.subject.value,
      Full_Name: e.target.name.value,
    };
    const error = nameError || emailError || subjectError || messageError;
    if(error){
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }else{
      saveToSpreadSheet(
        "https://api.apispreadsheets.com/data/vDNT3xfCA8b71EPi/",
        data,false
      );
      sendEmail(
        e,
        form,
        "service_mfk3jqe",
        "template_bic08sa",
        "JnYS958Pmm11LFY7I",
        true
      );
    }

  
  };
  return (
    <form
      ref={form}
      onSubmit={handelEmailSend}
      className="grid w-full  gap-5 "
    >
      <input
        onChange={(event) => handelError(event,"Name", setNameError, 100)}
        type="text"
        name="name"
        required
        placeholder="Full Name"
        className={
          nameError
            ? "input rounded-none border-2 border-[red] "
            : "input rounded-none border-2 border-black"
        }
      />

      <input
        onChange={(event) => handelError(event, "Email", setEmailError, 100)}
        type="email"
        name="email"
        required
        placeholder="Email Address"
        className={
          emailError
            ? "input rounded-none border-2 border-[red] "
            : "input rounded-none border-2 border-black"
        }
      />

      <input
        onChange={(event) => handelError(event, "Subject", setSubjectError, 100)}
        type="text"
        name="subject"
        required
        placeholder="Subject"
        className={
          subjectError
            ? "input rounded-none border-2 border-[red] "
            : "input rounded-none border-2 border-black"
        }
      />

      <textarea
        onChange={(event) => handelError(event, "Message", setMessageError, 300)}
        rows="4"
        name="message"
        required
        placeholder="Your Message"
        className={
          messageError
            ? "p-4 rounded-none border-2 border-[red] "
            : "p-4  rounded-none border-2 border-black"
        }
      ></textarea>

      <button type="submit" className="w-[165px] send">
        <span className="mr-1 font-bold">Send Message </span>{" "}
        <i className="fa-solid fa-arrow-right font-bold"></i>
      </button>
      <ToastContainer />
    </form>
  );
};

export default ContactForm;
